import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import OtherHousesIcon from "@mui/icons-material/OtherHouses";
import { Link, useNavigate } from "react-router-dom";
import QuizIcon from "@mui/icons-material/Quiz";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import HandshakeIcon from "@mui/icons-material/Handshake";
import WorkIcon from "@mui/icons-material/Work";
import Cookies from "js-cookie";
import { ListSubheader } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useState } from "react";
import { useEffect } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import BaseApi from "../api/BaseApi";
import ApiKey from "../api/ApiKey";
import Swal from "sweetalert2";
import axios from "axios";
import PersonIcon from "@mui/icons-material/Person";
import { useTranslation } from "react-i18next";

export default function ButtonAppBar() {
  const [isDrawerOpen, setDrawerOpen] = React.useState(false);
  const [userName, setUserName] = useState();
  const [userType, setUserType] = useState();
  const [login, setLogin] = useState(false);
  const [t, i18n] = useTranslation("global");

  const navigate = useNavigate();

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  let primaryColor = Cookies.get("primaryColor");
  let secondaryColor = Cookies.get("secondaryColor");
  let siteLogo = Cookies.get("siteLogo");

  let profileImage = Cookies.get("profileImage");

  const [age, setAge] = React.useState("");



  const navSetter = () => {
    if (tokenKey) {
      setUserName(Cookies.get("fname"));
      setUserType(Cookies.get("user_type"));
      setLogin(true);
    }
  };

  let fname = Cookies.get("fname");
  const tokenKey = Cookies.get("tokenClient");

  useEffect(() => {
    navSetter();
  }, []);

  const handleLogOut = async () => {
    try {
      const confirmationResult = await Swal.fire({
        title: t("navHeaders.logout"),
        text: t("navHeaders.logoutConfirmTxt"),
        icon: "question",
        showCancelButton: true,
        confirmButtonText: t("navHeaders.yes"),
        cancelButtonText: t("navHeaders.no"),
      });
      if (confirmationResult.isConfirmed) {
        // console.log("before api")
        const response = await axios.post(BaseApi + "/users/logout", null, {
          headers: {
            "Content-Type": "application/json",
            key: ApiKey,
            token: tokenKey,
          },
        });
        // console.log(response)
          // sessionStorage.clear();
          Cookies.remove("tokenClient");
          Cookies.remove("user_type");
          Cookies.remove("fname");
          navigate("/");
          window.location.reload();
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });

          Toast.fire({
            icon: "success",
            title: t("navHeaders.successTitle"),
          });
         
      }
    } catch (error) {
      console.log(error.response.status);
      if(error.response.status === 500) {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: t("tokenExpired.tokenExpired"),
          icon: "warning",
          confirmButtonText: t("jobDescription.close"),
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      }
      if (error.message === "Network Error") {
        Cookies.remove("tokenClient");
        Cookies.remove("user_type");
        Cookies.remove("fname");
        navigate("/");
        Swal.fire({
          title: t("tokenExpired.tokenExpired"),
          icon: "warning",
          confirmButtonText: t("jobDescription.close"),
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      }
  
    }
  };

  const [selectedLanguage, setSelectedLanguage] = useState(
    Cookies.get("selectedLanguage") || ""
  );

  const currentLanguage = Cookies.get("selectedLanguage") || "en";

  const handleChangeLanguage = (selectedValue) => {
    if (selectedValue) {
      i18n.changeLanguage(selectedValue);
      window.scrollTo(0, 0);
    } else {
      i18n.changeLanguage(currentLanguage);
      window.scrollTo(0, 0);
    }
    window.location.reload();
    setSelectedLanguage(selectedValue);
    Cookies.set("selectedLanguage", selectedValue, { expires: 365 });
  };
  useEffect(() => {
    if (currentLanguage) {
      i18n.changeLanguage(currentLanguage);
      window.scrollTo(0, 0);
    }
    setSelectedLanguage(currentLanguage);
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static" sx={{ backgroundColor: "white" }}>
        <Toolbar>
          <div className="navLanguageBox">
            <FormControl>
              <InputLabel id="demo-simple-select-label"></InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={age}
                value={selectedLanguage}
                label="Age"
                // onChange={handleChange}
                className="custom-selectBox"
                onChange={(e) => handleChangeLanguage(e.target.value)}
              >
                <MenuItem value="en">
                  <img
                    width="30"
                    height="30"
                    src="https://img.icons8.com/color/48/usa-circular.png"
                    alt="usa-circular"
                  />
                </MenuItem>
                <MenuItem value="el">
                  <img
                    width="30"
                    height="30"
                    src="https://img.icons8.com/color/48/greece-circular.png"
                    alt="greece-circular"
                  />
                </MenuItem>
                
              </Select>
            </FormControl>
          </div>

          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, textAlign: "center" }}
          >
            <Link to="/">
              {siteLogo && (
                <img className="frontendNavLogo" src={siteLogo} alt="LS Logo"   loading="lazy"/>
              )}
              {!siteLogo && (
                <img
                  className="frontendNavLogo"
                  src="/Images/logo.png"
                  alt=""
                  loading="lazy"
                />
              )}
            </Link>
          </Typography>

          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 1, color: "blue" }}
            onClick={handleDrawerOpen}
          >
            <div className="navAuthName">
              {userType && tokenKey && (
                <>
                  <AccountCircleIcon />
                  {/* <img src={profileImage} /> */}

                  <span className="userNameOnNavbar">{userName}</span>
                </>
              )}
            </div>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={handleDrawerClose}
        sx={{ width: 450 }}
      >
        <List>
          <ListSubheader>
            <span className="navMenuHeader">{t("navHeaders.menu")}</span>
            <hr />
          </ListSubheader>
          <div className="navMenuLinksDiv">
            <Link to="/" className="navMenuLinks">
              <ListItem>
                <span className="navMenuIcon">
                  <OtherHousesIcon />
                </span>
                <ListItemText primary={t("navHeaders.home")} />
              </ListItem>
            </Link>
          </div>
          {login ? (
            <>
              {userType === "recruiter" && (
                <>
                  <div className="navMenuLinksDiv">
                    <Link to="/user/myprofile" className="navMenuLinks">
                      <ListItem onClick={handleDrawerClose}>
                        <span className="navMenuIcon">
                          <PersonIcon />
                        </span>
                        <ListItemText primary={t("navHeaders.myAccount")} />
                      </ListItem>
                    </Link>
                  </div>
                  
                </>
              )}
              {userType === "candidate" && (
                <>
                  <div className="navMenuLinksDiv">
                    <Link to="/candidates/myaccount" className="navMenuLinks">
                      <ListItem onClick={handleDrawerClose}>
                        <span className="navMenuIcon">
                          <PersonIcon />
                          {/* <img src={profileImage} /> */}
                        </span>
                        <ListItemText primary={t("navHeaders.myProfile")} />
                      </ListItem>
                    </Link>
                  </div>
                  
                </>
              )}
            </>
          ) : (
            <></>
          )}
          <div
            className="navMenuLinksDiv"
            style={{ display: `${userType === "recruiter" ? "none" : ""}` }}
          >
            <Link to="/searchjob" className="navMenuLinks">
              <ListItem onClick={handleDrawerClose}>
                <span className="navMenuIcon">
                  <WorkIcon />
                </span>
                <ListItemText primary={t("navHeaders.searchjobs")} />
              </ListItem>
            </Link>
          </div>
          <div
            className="navMenuLinksDiv"
            style={{ display: `${userType === "recruiter" ? "none" : ""}` }}
          >
            <Link to="/partnershipjob" className="navMenuLinks">
              <ListItem onClick={handleDrawerClose}>
                <span className="navMenuIcon">
                  <HandshakeIcon />
                </span>
                <ListItemText primary={t("navHeaders.partnershipjob")} />
              </ListItem>
            </Link>
          </div>

          <div className="navMenuLinksDiv">
            <Link to="/how-it-works" className="navMenuLinks">
              <ListItem onClick={handleDrawerClose}>
                <span className="navMenuIcon">
                  <AccountTreeIcon />
                </span>
                <ListItemText primary={t("navHeaders.howitworks")} />
              </ListItem>
            </Link>
          </div>

          

          <div className="navMenuLinksDiv">
            <Link to="/faq" className="navMenuLinks">
              <ListItem onClick={handleDrawerClose}>
                <span className="navMenuIcon">
                  <QuizIcon />
                </span>
                <ListItemText primary={t("navHeaders.faq")} />
              </ListItem>
            </Link>
          </div>

          {login ? (
            <div className="navMenuLinksDiv">
            <Link to="" className="navMenuLinks">
              <ListItem onClick={handleLogOut}>
                <span className="navMenuIcon">
                  <LogoutIcon />
                </span>
                <ListItemText primary={t("navHeaders.logout")} />
              </ListItem>
            </Link>
          </div>
          ): ""}

          <div className="navMenuLinksDiv">
            <Link to="/contact" className="navMenuLinks">
              <ListItem onClick={handleDrawerClose}>
                <span className="navMenuIcon">
                  <ContactMailIcon />
                </span>
                <ListItemText primary={t("navHeaders.contactus")} />
              </ListItem>
            </Link>
          </div>

          

          

          
        </List>
      </Drawer>
    </Box>
  );
}
