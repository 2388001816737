import React, { useEffect, useState } from "react";
import Footer from "../element/Footer";
import JSSidebar from "./JSSidebar";
import NavBar from "../element/NavBar";
import axios from "axios";
import BaseApi from "../api/BaseApi";
import ApiKey from "../api/ApiKey";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import Select from "react-select";

const JSEducationList = () => {

    const [loading, setLoading] = useState(false);
    const [basicCourseList, setBasicCourseList] = useState([]);
    const [educationDetails, setEducationDetails] = useState({
      basic_course_id: "",
      basic_university: "",
      basic_year: "",
      graduation_title: "",
    });
    const [validationErrors, setValidationErrors] = useState([]);
    const [specializationList, setSpecializationList] = useState([]);
    const [yearsList, setYearsList] = useState([]);
    const tokenKey = Cookies.get("tokenClient");
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate();

    const getSpecializationList = async (id) => {
        try {
          const response = await axios.post(
            BaseApi + `/course/getSpecialization/${id}`,
            null,
            {
              headers: {
                "Content-Type": "application/json",
                key: ApiKey,
                token: tokenKey,
              },
            }
          );
          setSpecializationList(response.data.response);
        } catch (error) {
          console.log("Couldn't get specialization");
        }
      };

    useEffect(() => {
        // Check if tokenKey is not present
        if (!tokenKey) {
          // Redirect to the home page
          navigate("/user/jobseekerlogin");
        } else {
          // TokenKey is present, fetch data or perform other actions
          getData();
          window.scrollTo(0, 0);
          // if(educationDetails.basic_course_id) {
          getSpecializationList(educationDetails.basic_course_id);
          // }
        }
      }, [tokenKey, navigate]);
    
      const getData = async () => {
        try {
          setLoading(true);
          const response = await axios.post(
            BaseApi + "/candidates/editEducation",
            null, // Pass null as the request body if not required
            {
              headers: {
                "Content-Type": "application/json",
                key: ApiKey,
                token: tokenKey,
              },
            }
          );
          setLoading(false);
    
          if (response.data.status === 200) {
            setBasicCourseList(response.data.response.basicCourseList);
            setEducationDetails(response.data.response.eduDetails);
            setYearsList(response.data.response.yearList);
            // Fetch specialization data for each saved course
            // response.data.response.eduDetails.forEach((edu) => {
            //   if (edu.basic_course_id) {
            //     getSpecializationList(edu.basic_course_id);
            //   }
            // });
            // console.log(educationDetails);
          } else if (response.data.status === 400) {
            Cookies.remove("tokenClient");
            Cookies.remove("user_type");
            Cookies.remove("fname");
            navigate("/");
            Swal.fire({
              title: response.data.message,
              icon: "warning",
              confirmButtonText: t("jobseekerEducation.close"),
            });
          } else {
            Swal.fire({
              title: response.data.message,
              icon: "error",
              confirmButtonText: t("jobseekerEducation.close"),
            });
          }
        } catch (error) {
          console.log("token check from catch");
          setLoading(false);
          if (error.message === "Network Error") {
            Cookies.remove("tokenClient");
            Cookies.remove("user_type");
            Cookies.remove("fname");
            navigate("/");
            Swal.fire({
              title: t("tokenExpired.tokenExpired"),
              icon: "warning",
              confirmButtonText: t("jobDescription.close"),
            });
            setTimeout(function () {
              window.location.reload();
            }, 3000);
          }
          console.log(error.message);
        }
      };
  return (
    <div>
      hi
    </div>
  )
}

export default JSEducationList
